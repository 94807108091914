<template>
  <div class="section-wrapper">
  <b-overlay :show="loader">
    <b-card class="mb-3">
      <b-card-title>
        <b-row>
          <b-col>
            <h6>Edition: {{ editItem.edition }}</h6>
          </b-col>
          <b-col class="text-right">
            <h6>Release Date: {{ dDate(editItem.release_date) }}</h6>
          </b-col>
        </b-row>
      </b-card-title>
      <b-card-text>
        <b-row>
          <b-col class="text-right">
            <img v-if="editItem.cover_photo" :src="editItem.cover_photo" class="mr-2" height="70" alt="Cover photo">
          </b-col>
        </b-row>
        <p v-if="editItem.page_content" v-html="editItem.page_content"></p>
     </b-card-text>
    </b-card>
    </b-overlay>
  </div>
</template>
<script>
export default {
  props: ['editItem'],
  name: 'Content',
  components: {
  },
  data () {
    return {
      loader: false,
      detailData: []
    }
  },
  created () {
  },
  mounted () {
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style>
</style>
